exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete_account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-detail-image-1-js": () => import("./../../../src/pages/library/detail/image/1.js" /* webpackChunkName: "component---src-pages-library-detail-image-1-js" */),
  "component---src-pages-library-detail-video-1-js": () => import("./../../../src/pages/library/detail/video/1.js" /* webpackChunkName: "component---src-pages-library-detail-video-1-js" */),
  "component---src-pages-library-index-js": () => import("./../../../src/pages/library/index.js" /* webpackChunkName: "component---src-pages-library-index-js" */),
  "component---src-pages-news-detail-1-js": () => import("./../../../src/pages/news/detail/1.js" /* webpackChunkName: "component---src-pages-news-detail-1-js" */),
  "component---src-pages-news-detail-2-js": () => import("./../../../src/pages/news/detail/2.js" /* webpackChunkName: "component---src-pages-news-detail-2-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-ai-index-js": () => import("./../../../src/pages/products/ai/index.js" /* webpackChunkName: "component---src-pages-products-ai-index-js" */),
  "component---src-pages-products-blockchain-index-js": () => import("./../../../src/pages/products/blockchain/index.js" /* webpackChunkName: "component---src-pages-products-blockchain-index-js" */),
  "component---src-pages-products-digital-index-js": () => import("./../../../src/pages/products/digital/index.js" /* webpackChunkName: "component---src-pages-products-digital-index-js" */),
  "component---src-pages-products-vr-ar-index-js": () => import("./../../../src/pages/products/vr_ar/index.js" /* webpackChunkName: "component---src-pages-products-vr-ar-index-js" */),
  "component---src-templates-library-detail-js": () => import("./../../../src/templates/library-detail.js" /* webpackChunkName: "component---src-templates-library-detail-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */)
}

